<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <!-- start data table -->
    <b-card :title="$t('Navbar.Orders')">
      <b-row>
        <!-- sorting Status -->
        <b-col md="2" class="my-1">
          <b-form-group
            :label="$t('Table_Head.Status')"
            label-cols-sm="4"
            label-align-sm="right"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              style="z-index:9999999;"
              v-model="status"
              class="border mr-0"
              size="sm"
              :text="statusName"
              variant="flat-primary"
            >
              <b-dropdown-item @click="changeStatus('All', 'all')">
                الكل
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeStatus(item.name, item.slug)"
                v-for="(item, index) in orderStatus"
                :key="index"
              >
                {{ item.name }}
                <b-badge pill variant="primary" class="float-right">{{
                  item.order_counts
                }}</b-badge>
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- sorting is portal -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="التصنيف"
            label-cols-sm="4"
            label-align-sm="right"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              style="z-index:9999999;"
              v-model="is_portal"
              class="border mr-0"
              size="sm"
              :text="is_portalName"
              variant="flat-primary"
            >
              <b-dropdown-item @click="changeIsPortal('All', 'all')">
                الكل
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeIsPortal(item.name, item.value)"
                v-for="(item, index) in is_portalStatus"
                :key="index"
              >
                {{ item.name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- sorting -->
        <b-col md="3" class="my-1">
          <b-form-group
            :label="$t('Table_Head.sortable')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                @change="sortingTable"
                v-model="sortBy"
                :options="sortOptions"
                class=""
              >
              </b-form-select>
              <b-form-select
                @change="sortingTable"
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class=""
              >
                <option :value="false">
                  {{ $t("Product_Index.ASC") }}
                </option>
                <option :value="true">
                  {{ $t("Product_Index.DESC") }}
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="3" class="my-1">
          <b-form-group
            :label="$t('Product_Index.Filter')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                @change="sortingTable"
                v-model="filter"
                type="search"
                :placeholder="$t('Product_Index.Search')"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="fetchData">
                  {{ $t("Table_Head.Search") }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height:2.142rem; line-height:0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

        <!-- actions -->
        <b-col cols="12">
          <b-table
            id="OrdersTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :per-page="perPage"
            @sort-changed="fetchData"
            :current-page="currentPage"
            :items="fetchData"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template #cell(desc)="data">
              <b-card-text ><p v-html="data.value"></p> </b-card-text>
            </template>
            <template #cell(orderstatus)="data">
              <b-badge variant="warning">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(is_portal)="data">
              <b-badge v-if="data.value" variant="primary">
                بيع منصه
              </b-badge>
              <b-badge v-else variant="secondary">
                بيع مباشر
              </b-badge>
            </template>
            <template #cell(total)="data">
              <span class="text-primary text-left"> {{ data.value }} جنية</span>
            </template>
            <template #cell(actions)="items">
              <div>
                <!-- Show Order -->
                <b-button
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{ path: '/orders/show/' + items.item.id }"
                >
                  <feather-icon
                    style="width:18px; height:18px;"
                    icon="EyeIcon"
                  />
                </b-button>
                <!-- update Order Status -->
                <b-button
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-if="items.item.valid_to_change_status"
                  variant="info"
                  type="submit"
                  :to="{ path: '/orders/Update/Status/' + items.item.id }"
                >
                  {{ $t("Product_Index.Change_Status") }}
                </b-button>
                <!-- delete Order -->
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body
        class="d-flex justify-content-between p-0 pagination-section"
      >
        <!-- page length -->
        <div class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{
            $t("Product_Index.Per_Page")
          }}</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </div>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {

  data() {
    return {
      per: null,
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: "created_at",
      status: "all",
      is_portal: "all",
      statusName: this.$t("Table_Head.All"),
      is_portalName: this.$t("Table_Head.All"),
      is_portalStatus: [
        {
          name: "بيع منصة",
          value: "portal",
        },
        {
          name: "بيع مباشر",
          value: "in_store",
        },
      ],
      sortDesc: true,
      sortDirection: "desc",
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      // table fields
      fields: [
        {
          key: "id",
          label: this.$t("Table_Head.ID"),
          sort_by: true,
        },
        {
          key: "username",
          label: this.$t("Table_Head.UserName"),
        },
        {
          key: "code",
          label: this.$t("Table_Head.Code"),
        },
        {
          key: "orderstatus",
          label: this.$t("Table_Head.Order_Status"),
        },
        {
          key: "is_portal",
          label: "التصنيف",
        },
        {
          key: "total",
          label: this.$t("Table_Head.Total"),
        },
        {
          key: "type",
          label: this.$t("Table_Head.Type_Payment"),
        },
        {
          key: "discount",
          label: this.$t("Table_Head.Discount"),
        },

        {
          key: "created_at",
          label: this.$t("Table_Head.Created_at"),
          sort_by: true,
        },
        {
          key: "updated_at",
          label: this.$t("Table_Head.Updated_at"),
          sort_by: true,
        },
        {
          key: "actions",
          label: this.$t("Table_Head.Actions"),
        },
      ],
      items: [],
      exportItems: [],
      orderStatus: []
    };
  },
  created() {
    // request Orders index
    
    axios
      .get("order-status/index")
      .then((result) => {
        this.orderStatus = result.data.data;
        this.fetchData();
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sort_by)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
    rows() {
      return this.totalRows;
    },
  },
  watch: {
    sortDirection: function() {
      this.sortingTable();
    },
    sortBy: function() {
      this.sortingTable();
    },
    status: function() {
      this.fetchData();
    },
    is_portal: function() {
      this.fetchData();
    },
    filter: function() {
      if (this.filter == "" || this.filter == null) {
        this.fetchData();
      }
    },
  },
  methods: {
    // fetch data of Orders
    fetchData() {
      var url = null;
      if (this.filter == "") {
        url =
          "orders/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.status +
          "/" +
          this.is_portal +
          "?page=" +
          this.currentPage;
      } else {
        url =
          "orders/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.status +
          "/" +
          this.is_portal +
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          const data = result.data.data.data;
          this.totalRows = result.data.data.meta.total;
          this.items.length = 0;
          this.items = data
          this.exportItems = this.items;
          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    // sort function
    sortingTable() {
      this.currentPage = 1;
      if (this.sortDesc == true) {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }
      var url = null;
      if (this.filter == "") {
        url =
          "orders/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.status +
          "/" +
          this.is_portal +
          "?page=" +
          this.currentPage;
      } else {
        url =
          "orders/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.status +
          "/" +
          this.is_portal +
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          const data = result.data.data.data;
          this.totalRows = result.data.data.meta.total;
          this.items.length = 0;
          this.items = data
          this.exportItems = this.items;
          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    // delete function of (delete button) Order
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete order.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("orders/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    changeStatus(name, slug) {
      this.status = slug;
      this.statusName = name;
    },
    changeIsPortal(name, value) {
      this.is_portal = value;
      this.is_portalName = name;
    },
    genPDF() {
                const { jsPDF } = require("jspdf");
      const doc = new jsPDF();
      let print_body = [];
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].username,
          this.items[index].code,
          this.items[index].orderstatus,
          this.items[index].totla,
          this.items[index].discount,
          this.items[index].shipping_price,
          this.items[index].created_at,
          this.items[index].updated_at,
        ];
      }
      doc.autoTable({
        head: [
          [
            "ID",
            "User Name",
            "Code",
            "Order Status",
            "Totla",
            "Discount",
            "Shipping Price",
            "CREATED_AT",
            "UPDATED_AT",
          ],
        ],
        body: print_body,
      });
      doc.save("table.pdf");
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
<style>
@media only screen and (max-width: 425px) {
  .pagination-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pagination-section div {
    margin: 10px;
  }
}
</style>
